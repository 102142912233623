/**
 * Vue i18n
 *
 * @library
 *
 * http://kazupon.github.io/vue-i18n/en/
 */

// Lib imports
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/lang'

Vue.use(VueI18n)

const defined_messages = require('../lang/ja/Messages.json')
const i18n = new VueI18n({
  locale: 'ja',
  fallbackLocale: 'ja',
  messages: defined_messages,
  silentTranslationWarn: true
})

export default i18n
