<template>
  <v-container
    fluid
    fill-height
    style="height:80vh">
    <core-toolbar />
    <v-row
      justify="center"
      align="center">
      <v-col
        class="col-11 col-sm-8 col-md-4 col-xl-3">
        <v-card
          outlined>
          <v-card-text>
            <v-form>
              <v-text-field
                ref="username"
                v-model="username"
                :rules="[() => !!username || '必須入力項目です']"
                prepend-icon="mdi-account"
                label="ログインID"
                placeholder="ログインID"
                required />
              <v-text-field
                ref="password"
                v-model="password"
                :rules="[() => !!password || '必須入力項目です']"
                type="password"
                prepend-icon="mdi-lock"
                label="パスワード"
                placeholder="パスワード"
                required
                @keydown.enter="login" />
            </v-form>
          </v-card-text>
            
          <v-card-actions>
            <v-btn
              block
              align-center
              justify-center
              dark
              color="indigo darken-4"
              @click="login">
              ログイン
            </v-btn>
          </v-card-actions>
          <v-snackbar
            v-model="snackbar"
            :color="color"
            :top="true">
            {{ errorMessages }}
            <v-btn
              dark
              @click="snackbar = false">
              閉じる
            </v-btn>
          </v-snackbar>
        </v-card>
        <!--
        <v-alert
          class="mt-4"
          dense
          outlined
          type="info">
          メンテナンス情報 <strong>12/03 20:00〜21:00</strong> は利用できません
        </v-alert>
        -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: function () {
    return {
      username: '',
      password: '',
      errorMessages: 'ログイン失敗',
      snackbar: false,
      color: 'general',
      showPassword: false
    }
  },

  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  methods: {
    login: function () {
      let username = this.username
      let password = this.password
      this.$store.dispatch('login', { username, password })
        .then(() => this.$router.push('/price'))
        .catch(err => {
          console.log(err)
          this.snackbar = true
        }
        )
    }
  },
  metaInfo () {
    return {
      title: 'Price Management System | Login'
    }
  }
}
</script>
