import axios from 'axios'
import store from '@/store'
/**
 * Responsible for all HTTP requests.
 */

export default {
  request (method, url, data, successCb = null, errorCb = null) {
    if (method.toLowerCase() === 'get' || method.toLowerCase() === 'delete') {
      axios({
        url: url,
        params: data,
        method: method.toLowerCase()
      }).then(successCb).catch(function(err) {
        if (!errorCb) {
          this.handleError(err)
        } else {
          errorCb(err)
        }
      }.bind(this))
    } else {
      axios({
        url: url,
        data: data,
        method: method.toLowerCase()
      }).then(successCb).catch(function(err) {
        if (!errorCb) {
          this.handleError(err)
        } else {
          errorCb(err)
        }
      }.bind(this))
    }
  },

  get (url, data = {}, successCb = null, errorCb = null) {
    return this.request('get', url, data, successCb, errorCb)
  },

  post (url, data, successCb = null, errorCb = null) {
    return this.request('post', url, data, successCb, errorCb)
  },

  put (url, data, successCb = null, errorCb = null) {
    return this.request('put', url, data, successCb, errorCb)
  },

  delete (url, data = {}, successCb = null, errorCb = null) {
    return this.request('delete', url, data, successCb, errorCb)
  },
  byMethod (method, url, data, successCb = null, errorCb = null) {
    return this.request(method, url, data, successCb, errorCb)
  },
  handleError(error) {
    if (!error.response) {
      // network error
      //this.$toaster.info('Your toaster info message.')
      alert('Error: Network Error')
    } else {
      if (error.response.status === 401 || error.response.status === 419) {
        //419はLaravel固有の例外
        alert('認証エラー')
        store.dispatch('logout')
        //localStorage.removeItem('token')
        window.location = '/'
      }

      if (error.response.status === 403) {
        alert('Unauthorized access!')
      }

      if (error.response.status === 409) {
        alert(error.response.statusText)
      }

      if (error.response.status === 500) {
        alert(error.response.statusText)
      }

      if (error.response.status === 422) {
        alert(error.response.statusText)
      }

      if (error.response.status === 404) {
        alert(error.response.statusText)
      }

      if (error.response.status === 405) {
        alert('許可されていない要求です(ErrorCode:405)')
      }
    }
    
    console.log(error)
  },

  /**
     * Init the service.
     */
  init () {
    // Sets the default url used by all of this axios instance's requests
    axios.defaults.baseURL = process.env.VUE_APP_API_SERVER_URL
    axios.defaults.headers.get['Accept'] = 'application/json'
    console.log(axios.defaults.baseURL)
    
    const token = store.getters.token
    if (token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    }

    // Intercept the response and ...
    axios.interceptors.response.use(response => {
      return response
    }, error => {
      // Also, if we receive a Bad Request / Unauthorized error
      console.log(error)
      throw error
      /*
      if (error.response) {
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      
      //return Promise.reject(error)
      if (error.response.status === 401) {
        if (this.$store.getters.authorized) {
          
          this.$store.dispatch('refreshtoken')
          
        } else {
          return Promise.reject(error)
        }
      } else {
        return Promise.reject(error)
      }
      */
    })
  },
  
}

