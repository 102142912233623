<template>
  <v-app>
    <transition mode="out-in">
      <router-view />
    </transition>

    <v-footer
      color="indigo darken-4"
      dark
      app>
      {{ $t("copyright") }}
      <v-spacer />
      <span class="hidden-xs-only">
        {{ $t("version") }}
      </span>
    </v-footer>
  </v-app>
</template>

<style lang="scss">
	@import "@/styles/index.scss";

	/* Remove in 1.2 */
	.v-datatable thead th.column.sortable i {
		vertical-align: unset;
	}
</style>
<script>
// checks to see if auth jwt token is valid or has expired, if it gets back 401 error log out
export default {
  created: function () {
    console.log(this.$t("version"))
    /*
    this.$http.interceptors.response.use((response) => {
      return response
    }, (error) => {
      if (error.response.status === 401) {
        if (this.$store.getters.authorized) {
          this.$store.dispatch('refreshtoken')
        } else {
          return Promise.reject(error)
        }
      } else {
        return Promise.reject(error)
      }
    })
    */
  }
}
</script>
