<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <v-row
      v-if="item!==null"
      no-gutters
      align="center">
      <v-col
        v-show="!responsive"
        cols="auto"
        class="mr-6 black--text">
        {{ strDataGetDate }}
      </v-col>
      
      <v-col
        cols="auto"
        class="ml-2">
        <v-simple-table
          dense
          class="my-detail-table">
          <template v-slot:default>
            <tbody>
              <tr>
                <td
                  class="my-table-header def-header"
                  style="width:6rem;">
                  タイプ
                </td>
                <td
                  style="min-width:8rem;"
                  class="text-center">
                  {{ item[0].sales_kind_name }}
                </td>
                <td
                  class="my-table-header def-header"
                  style="width:6rem;">
                  契約種別
                </td>
                <td
                  class="text-center"
                  style="min-width:8rem;">
                  {{ item[0].contract_kind_name }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-spacer />
      <v-col
        cols="auto"
        class="mr-6">
        <v-slide-group
          v-model="viewMode"
          class="pa-2"
          mandatory>
          <v-slide-item
            v-for="n in 2"
            :key="n"
            v-slot="{ active, toggle }">
            <v-btn
              class="mx-1"
              :input-value="active"
              active-class="primary white--text"
              depressed
              rounded
              small
              @click="toggle">
              {{ viewModeText[n-1] }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
    <v-row v-show="responsive">
      <v-spacer />
      <v-col
        cols="auto"
        class="mr-2 black--text">
        {{ strDataGetDate }}
      </v-col>
    </v-row>
    <v-row v-if="item!==null">
      <v-col
        class="pt-0"
        cols="12"
        :sm="12"
        :md="colMdSize"
        :offset-sm="0"
        :offset-md="colMdOffset">
        <v-simple-table
          class="my-detail-table mt-0">
          <template v-slot:default>
            <tbody>
              <tr>
                <td
                  colspan="3"
                  nowrap
                  style="width:30rem;"
                  class="my-table-header def-header">
                  機種
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  colspan="2">
                  {{ mdl.model_name }}
                </td>
              </tr>
              <tr>
                <td
                  colspan="3"
                  class="my-table-header def-header">
                  分類（カテゴリ）
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  colspan="2">
                  {{ mdl.model_kind_name }}
                </td>
              </tr>
                  
              <tr>
                <td
                  rowspan="13"
                  nowrap
                  class="my-table-header def-header">
                  (税込)
                </td>
                
                <td
                  nowrap
                  colspan="2"
                  class="def-header">
                  価格
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  colspan="2"
                  class="number-cell">
                  <span v-html="formatNumberAmount(mdl.store_price)" />
                </td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  class="def-header">
                  頭金
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  colspan="2"
                  class="number-cell">
                  <span v-html="formatNumberAmount(mdl.store_deposit)" />
                </td>
              </tr>
              <tr>
                <td
                  rowspan="3"
                  class="my-table-header def-header">
                  分割払い
                </td>
                <td class="def-header">
                  回数
                </td>
                <template
                  v-for="(mdl) in item">
                  <td
                    v-for="(text, idx) in instPayCount"
                    :key="mdl.id+idx"
                    class="def-header text-center">
                    {{ text }}
                  </td>
                </template>
              </tr>
              <tr>
                <td class="def-header">
                  1回目
                </td>
                <template
                  v-for="(mdl) in item">
                  <td
                    v-for="(text, idx) in instPayCount"
                    :key="mdl.id+idx"
                    class="number-cell">
                    <span
                      v-if="idx==0"
                      v-html="formatNumberAmount(mdl.inst_payment_24_1st_amount)" />
                    <span
                      v-else
                      v-html="formatNumberAmount(mdl.inst_payment_36_1st_amount)" />
                  </td>
                </template>
              </tr>
              <tr>
                <td class="def-header">
                  2回目以降
                </td>
                <template
                  v-for="(mdl) in item">
                  <td
                    v-for="(text, idx) in instPayCount"
                    :key="mdl.id+idx"
                    class="number-cell">
                    <span
                      v-if="idx==0"
                      v-html="formatNumberAmount(mdl.inst_payment_24_2nd_amount)" />
                    <span
                      v-else
                      v-html="formatNumberAmount(mdl.inst_payment_36_2nd_amount)" />
                  </td>
                </template>
              </tr>
              <tr>
                <td
                  colspan="2"
                  class="def-header">
                  分割支払総額
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  colspan="2"
                  class="number-cell">
                  <span v-html="formatNumberAmount(mdl.inst_payment_total_amount)" />
                </td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  class="def-header">
                  お返し・カエドキ適用時負担額
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  colspan="2"
                  class="number-cell">
                  <span
                    v-if="mdl.target_pg_num==1"
                    v-html="formatNumberAmount(mdl.kaedoki_futan_amount)" />
                  <span
                    v-else-if="mdl.target_pg_num==2"
                    v-html="formatNumberAmount(mdl.okaeshi_futan_amount)" />
                  <span v-else>{{ strHyphen }}</span>
                </td>
              </tr>
              <tr>
                <td
                  rowspan="6"
                  class="my-table-header blue-header">
                  【いつでもカエドキPG】
                </td>
                <td
                  nowrap
                  class="blue-header">
                  1回目
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  colspan="2"
                  class="number-cell">
                  <span
                    v-if="mdl.target_pg_num==1"
                    v-html="formatNumberAmount(mdl.kaedoki_24_1st_amount)" />
                  <span v-else>{{ strHyphen }}</span>
                </td>
              </tr>
              <tr>
                <td class="blue-header">
                  2～23回目
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  colspan="2"
                  class="number-cell">
                  <span
                    v-if="mdl.target_pg_num==1"
                    v-html="formatNumberAmount(mdl.kaedoki_24_2nd_amount)" />
                  <span v-else>{{ strHyphen }}</span>
                </td>
              </tr>
              <tr>
                <td class="blue-header">
                  24回目(残価)
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  colspan="2"
                  class="number-cell">
                  <span
                    v-if="mdl.target_pg_num==1"
                    v-html="formatNumberAmount(mdl.remain_amount)" />
                  <span v-else>{{ strHyphen }}</span>
                </td>
              </tr>
              <tr>
                <td class="blue-header">
                  再分割1回目
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  colspan="2"
                  class="number-cell">
                  <span
                    v-if="mdl.target_pg_num==1"
                    v-html="formatNumberAmount(mdl.kaedoki_re24_1st_amount)" />
                  <span v-else>{{ strHyphen }}</span>
                </td>
              </tr>
              <tr>
                <td
                  nowrap
                  class="blue-header">
                  再分割2～24回目
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  colspan="2"
                  class="number-cell">
                  <span
                    v-if="mdl.target_pg_num==1"
                    v-html="formatNumberAmount(mdl.kaedoki_re24_2nd_amount)" />
                  <span v-else>{{ strHyphen }}</span>
                </td>
              </tr>
              <tr>
                <td class="blue-header">
                  早期利用特典/月
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  colspan="2"
                  class="number-cell">
                  <span
                    v-if="mdl.target_pg_num==1"
                    v-html="formatNumberAmount(mdl.benefit_discount_1)" />
                  <span v-else>{{ strHyphen }}</span>
                </td>
              </tr>
              <tr>
                <td
                  rowspan="3"
                  nowrap
                  class="my-table-header def-header">
                  (税抜)
                </td>
                <td
                  rowspan="3"
                  class="my-table-header purple-header">
                  ドコモ施策
                </td>
                <td
                  nowrap
                  class="purple-header">
                  ダイレクト割
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  colspan="2"
                  class="number-cell">
                  <span v-html="formatNumberAmount(mdl.direct_discount_amount)" />
                </td>
              </tr>
              <tr>
                <td class="purple-header">
                  機種購入特典
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  colspan="2"
                  class="number-cell">
                  <span v-html="formatNumberAmount(mdl.w_rom_direct_discount_amount)" />
                </td>
              </tr>
              <tr>
                <td class="purple-header">
                  クーポン
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  colspan="2"
                  class="number-cell">
                  <span v-html="formatNumberAmount(mdl.coupon_discount_amount)" />
                </td>
              </tr>

              <template v-if="viewMode==1 && (item.length>1 || responsive)">
                <tr>
                  <td
                    rowspan="8"
                    nowrap
                    class="my-table-header def-header">
                    (税抜)
                  </td>
                  <td
                    colspan="2"
                    class="my-table-header yellow-header">
                    ALADIN設定価格
                  </td>
                  <td
                    v-for="(mdl, key) in item"
                    :key="key"
                    colspan="2"
                    class="number-cell">
                    <span v-html="formatNumberAmount(mdl.aladin_price)" />
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="2"
                    class="my-table-header yellow-header">
                    POS売価マスタ
                  </td>
                  <td
                    v-for="(mdl, key) in item"
                    :key="key"
                    colspan="2"
                    class="number-cell">
                    <span v-html="formatNumberAmount(mdl.pos_price)" />
                  </td>
                </tr>

                <tr>
                  <td
                    rowspan="6"
                    class="my-table-header def-header">
                    POS登録
                  </td>
                  <td
                    nowrap
                    class="green-header">
                    店舗本体値引
                  </td>
                  <td
                    v-for="(mdl, key) in item"
                    :key="key"
                    colspan="2"
                    class="number-cell">
                    <span v-html="formatNumberAmount(mdl.store_discount_amount)" />
                  </td>
                </tr>
                <tr>
                  <td class="green-header">
                    店舗特別割引
                  </td>
                  <td
                    v-for="(mdl, key) in item"
                    :key="key"
                    colspan="2"
                    class="number-cell">
                    <span v-html="formatNumberAmount(mdl.store_w_rom_discount_amount)" />
                  </td>
                </tr>
                <tr>
                  <td class="green-header">
                    クーポン未保有者値引
                  </td>
                  <td
                    v-for="(mdl, key) in item"
                    :key="key"
                    colspan="2"
                    class="number-cell">
                    <span v-html="formatNumberAmount(mdl.store_coupon_discount_amount)" />
                  </td>
                </tr>
                
                <tr>
                  <td class="orange-header">
                    エリア基準値引
                  </td>
                  <td
                    v-for="(mdl, key) in item"
                    :key="key"
                    colspan="2"
                    class="number-cell">
                    <span v-html="formatNumberAmount(mdl.area_discount_amount)" />
                  </td>
                </tr>
                <tr>
                  <td
                    nowrap
                    class="orange-header">
                    エリア基準特別割引
                  </td>
                  <td
                    v-for="(mdl, key) in item"
                    :key="key"
                    colspan="2"
                    class="number-cell">
                    <span v-html="formatNumberAmount(mdl.area_w_rom_discount_amount)" />
                  </td>
                </tr>
                <tr>
                  <td
                    nowrap
                    class="orange-header">
                    店舗値引残額
                  </td>
                  <td
                    v-for="(mdl, key) in item"
                    :key="key"
                    colspan="2"
                    class="number-cell">
                    <span v-html="formatNumberAmount(mdl.store_discount_zan)" />
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col
        v-if="viewMode==1 && item.length==1 && !responsive"
        class="pt-0"
        cols="12"
        sm="12"
        md="6">
        <v-simple-table
          height="100%"
          class="my-detail-table"
          dense>
          <template v-slot:default>
            <tbody>
              <!--
              <tr>
                <td
                  colspan="3"
                  style="width:30rem;" />
                <td />
              </tr>
              -->
              <tr>
                <td
                  rowspan="8"
                  nowrap
                  style="width:6rem;"
                  class="my-table-header def-header">
                  (税抜)
                </td>
                <td
                  colspan="2"
                  style="width:16rem;"
                  class="my-table-header yellow-header">
                  ALADIN設定価格
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  class="number-cell">
                  <span v-html="formatNumberAmount(mdl.aladin_price)" />
                </td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  class="my-table-header yellow-header">
                  POS売価マスタ
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  class="number-cell">
                  <span v-html="formatNumberAmount(mdl.pos_price)" />
                </td>
              </tr>

              <tr>
                <td
                  rowspan="6"
                  class="my-table-header def-header">
                  POS登録
                </td>
                <td
                  nowrap
                  class="green-header">
                  店舗本体値引
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  class="number-cell">
                  <span v-html="formatNumberAmount(mdl.store_discount_amount)" />
                </td>
              </tr>
              <tr>
                <td class="green-header">
                  店舗特別割引
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  class="number-cell">
                  <span v-html="formatNumberAmount(mdl.store_w_rom_discount_amount)" />
                </td>
              </tr>
              <tr>
                <td class="green-header">
                  クーポン未保有者値引
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  colspan="2"
                  class="number-cell">
                  <span v-html="formatNumberAmount(mdl.store_coupon_discount_amount)" />
                </td>
              </tr>
              <tr>
                <td class="orange-header">
                  エリア基準値引
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  class="number-cell">
                  <span v-html="formatNumberAmount(mdl.area_discount_amount)" />
                </td>
              </tr>
              <tr>
                <td
                  nowrap
                  class="orange-header">
                  エリア基準特別割引
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  class="number-cell">
                  <span v-html="formatNumberAmount(mdl.area_w_rom_discount_amount)" />
                </td>
              </tr>
              <tr>
                <td
                  nowrap
                  class="orange-header">
                  店舗値引残額
                </td>
                <td
                  v-for="(mdl, key) in item"
                  :key="key"
                  class="number-cell">
                  <span v-html="formatNumberAmount(mdl.store_discount_zan)" />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row v-if="!isDialog">
      <v-col>
        <v-divider />
        <v-btn
          block
          text
          color="indigo"
          @click="onClickBack">
          <v-icon class="mr-2">
            mdi-arrow-left
          </v-icon>
          戻る
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
    
<script>
import { formatNumberCommaWithStyle } from "../../plugins/util.js"

export default {
  props: {
    item: {
      type: [Object, Array],
      default: null
    },
    strDataGetDate: {
      type: String,
      default: ""
    },
    isDialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    viewMode: 0,
    viewModeText: [
      "ご説明",
      "スタッフ"
    ],
    instPayCount: [
      "24回",
      "36回"
    ],
    strHyphen: "ー",
    responsive: false,
  }),
  computed: {
    formatNumberAmount() {
      return function(val, prefix="¥") {
        if (prefix) {
          if (val >= 0) {
            return prefix + " " + formatNumberCommaWithStyle(val)
          } else {
            return `<span style="color: red;">${prefix}</span>` + formatNumberCommaWithStyle(val)
          }
        }
        return formatNumberCommaWithStyle(val)
      }
    },
    colSmSize() {
      return 0
    },
    colMdSize() {
      if (this.viewMode==0) {
        if (this.item.length==1) {
          return 8
        } else {
          return 12
        }
      }
      if (this.item.length==1) {
        return 6
      } else {
        return 12
      }
    },
    colSmOffset() {
      return 0
    },
    colMdOffset() {
      if (this.viewMode==0 && this.item.length==1) {
        return 2
      }
      return 0
    }
    
  },
  watch: {
    item (val) {
      this.viewMode = 0
    },
    
  },
  created () {
    //console.log("created")
  },
  mounted () {
    //console.log("mounted")
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  methods: {
    onClickBack () {
      this.$router.go(-1)
    },
    onResponsiveInverted () {
      if (window.innerWidth < 1024) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    },
  }
}
</script>
    
<style>
.my-block-parts {
  padding: 2px;
}
.number-cell {
  white-space: nowrap;
  text-align: right;
}
@media all and (max-width: 1020px) {
  .migre-select {
    font-size: 16px;
    transform: scale(0.9);
    margin-left: -1.3rem;
  }
}
.section1-label {
  width: 10rem !important;
}
</style>

<style scoped>

</style>