<template>
  <!-- eslint-disable vue/no-v-text-v-html-on-component -->
  <!-- eslint-disable vue/no-v-html -->
  <v-app-bar
    color="indigo darken-4"
    dark
    clipped-left
    app>
    <div class="v-toolbar-title header-logo">
      <v-toolbar-title :class="titleClass">
        <v-btn
          v-if="enableBackBtn"
          icon
          class="mx-1"
          @click="onBackUrl">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        {{ title }}
      </v-toolbar-title>
    </div>
    <!--<template v-if="showCarrierSelect">
      <v-select
        v-model="select_carrier_code"
        :items="carriersList"
        item-text="carrier_name"
        item-value="carrier_code"
        dense
        outlined 
        single-line
        :menu-props="{offsetY: true}"
        style="max-width:12rem;"
        class="y-auto ml-5 mr-5" 
        hide-details="auto"
        @change="changeCarrierSelectTab" />
    </template>
-->
    <v-item-group
      v-if="showCarrierSelect"
      v-model="tabsel_carrier"
      class="y-auto ml-5"
      mandatory
      @change="changeCarrier">
      <v-row>
        <v-col
          v-for="citem in carriersList"
          :key="citem.carrier_code"
          cols="6"
          md="6">
          <v-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? 'pink' : 'grey lighten-3'"
              class="d-flex align-center"
              height="32"
              @click="toggle">
              <div
                v-if="active"
                class="sub-title flex-grow-1 text-center ml-5 mr-5">
                {{ citem.carrier_name }}
              </div>
              <div
                v-else
                class="sub-title flex-grow-1 text-center ml-5 mr-5 grey--text">
                {{ citem.carrier_name }}
              </div>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>
    <v-spacer />
    <template v-if="authorized">
      <span
        v-if="!responsive"
        class="text-no-wrap body-2">タイプ：</span>
      <select
        v-model="qry_sales_kind"
        name="saleskind_select"
        class="mr-3"
        style="border-style:solid;padding:6px;background-color:#FFF;width:14rem;"
        @change="changeQrySalesKind(true)">
        <option
          v-for="item in salesKindList"
          :key="item.id"
          :value="item.sales_kind_code">
          {{ item.sales_kind_name }}
        </option>
      </select>
      <span
        v-if="!responsive"
        class="text-no-wrap body-2">契約種別：</span>
      <select
        v-model="qry_contract_kind"
        name="contractkind_select"
        class="mr-3"
        style="border-style:solid;padding:6px;background-color:#FFF;width:10rem;"
        @change="changeQryContractKind()">
        <option
          v-for="item in contractKindList"
          :key="item.id"
          :value="item.contract_kind_code">
          {{ item.contract_kind_name }}
        </option>
      </select>
      <!--
      <v-select
        v-model="qry_sales_kind"
        :items="salesKindList"
        item-text="sales_kind_name"
        item-value="sales_kind_code"
        label="タイプ"
        dense
        outlined
        background-color="black"
        
        hide-details="true"
        :menu-props="{offsetY: true, closeOnClick: true, maxHeight:500}"
        style="max-width:12rem;"
        class="mr-5" 
        @change="changeQrySalesKind()" />
      <v-select
        v-model="qry_contract_kind"
        :items="contractKindList"
        item-text="contract_kind_name"
        item-value="contract_kind_code"
        label="契約種別"
        dense
        outlined
        background-color="black"

        hide-details="true"
        :menu-props="{offsetY: true, closeOnClick: true, maxHeight:500}"
        style="max-width:12rem;"
        class="mr-5" 
        @change="changeQryContractKind()" />
        -->
    </template>
    
    <!--
    <v-spacer />
    -->
    <v-tooltip
      v-if="!responsive && authorized"
      bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          icon
          class="mx-2"
          v-on="on"
          @click="resetKinds">
          <v-icon>my_location</v-icon>
        </v-btn>
      </template>
      <span>タイプ、契約種別をリセット</span>
    </v-tooltip>
    
    <v-tooltip
      v-if="!responsive && authorized"
      bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          icon
          class="mx-2"
          v-on="on"
          @click="refresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>最新データを取得</span>
    </v-tooltip>

    <v-menu
      v-if="authorized"
      ref="notificationlist"
      bottom
      left
      offset-y
      :close-on-content-click="false"
      :disabled="notificationItems.length <= 0"
      @input="onNotificationMenuToggle">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          class="ml-2 mr-2"
          v-on="on">
          <v-icon>
            mdi-bell
          </v-icon>
          <v-badge
            v-if="notificationNewCount>0"
            color="pink"
            dot />
        </v-btn>
      </template>
      <v-card width="460">
        <v-toolbar
          color="white"
          class="pa-0"
          flat
          dense>
          <v-row>
            <v-col cols="2">
              <v-btn
                v-if="showNotificationItem"
                icon
                @click="showNotificationItem=null">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </v-col>
            <v-col class="d-flex justify-space-around my-auto">
              <v-toolbar-title class="text-h6">
                お知らせ
              </v-toolbar-title>
            </v-col>
            <v-col
              cols="2"
              class="d-flex justify-end">
              <v-btn
                icon
                @click="$refs.notificationlist.isActive = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-divider />
        <v-card-text
          v-if="showNotificationItem==null"
          class="pa-0"
          style="max-height: 400px; overflow-y: scroll">
          <v-list
            three-line>
            <v-list-item-group
              v-model="selectNotificationIndex"
              active-class=""
              @change="showNotifivation">
              <template v-for="(item, index) in notificationItems">
                <v-list-item
                  :key="item.title"
                  style="background-color:#FFFFFF;color:black">
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-subtitle-1"
                      v-html="notificationTitle(item)" />
                    <v-list-item-subtitle
                      class="mt-1 mb-1"
                      v-html="item.body_text" />
                    <v-list-item-subtitle v-html="notification3rdLineString(item)" />
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon
                      class="mt-4"
                      color="grey lighten-1">
                      mdi-chevron-right
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider
                  :key="index"
                  style="background-color:#FFFFFF;color:black"
                  :inset="false" />
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-text
          v-else
          class="pa-3"
          style="height: 400px; overflow-y: scroll">
          <v-row>
            <v-col>
              <div
                class="text-subtitle-1"
                v-html="notificationTitle(showNotificationItem)" />
            </v-col>
          </v-row>
          <v-row
            no-gutters
            class="mb-2 mt-2">
            <v-col>
              <div
                class="body-2"
                v-html="notification3rdLineString(showNotificationItem)" />
            </v-col>
          </v-row>
          <v-divider class="mb-2" />
          <v-row>
            <v-col>
              <div
                class="body-2"
                v-html="showNotificationItem.body_text" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
    
    <v-menu
      v-if="authorized"
      bottom
      left
      offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on">
          <v-icon>
            mdi-dots-vertical
          </v-icon>
        </v-btn>
      </template>
      <v-list class="pub-menu">
        <v-list-item
          v-if="responsive"
          dense
          @click="resetKinds">
          <v-icon class="mr-2">
            my_location
          </v-icon>
          タイプ、契約種別リセット
        </v-list-item>
        <v-list-item
          v-if="responsive"
          dense
          @click="refresh">
          <v-icon class="mr-2">
            mdi-refresh
          </v-icon>
          データ更新
        </v-list-item>
        <v-list-item
          dense
          @click="logout">
          <v-icon class="mr-2">
            mdi-logout
          </v-icon>
          ログアウト
        </v-list-item>
        <!--
        <template v-if="isStoreAdmin">
          <v-divider />
          
          <v-list-item
            dense
            @click="changePwdAdmin">
            管理者パスワード更新
          </v-list-item>
          <v-list-item
            dense
            @click="changePwdStaff">
            スタッフパスワード更新
          </v-list-item>
        </template>
        -->
      </v-list>
    </v-menu>
    <template
      v-if="showCarrierTabSelect"
      v-slot:extension>
      <v-tabs 
        v-model="tabsel_carrier"
        align-with-title
        fixed-tabs
        slider-size="6"
        @change="changeCarrier">
        <v-tabs-slider color="orange" />
        <v-tab
          v-for="item in carriersList"
          :key="item.carrier_code"
          class="text-notransform"
          style="margin-left:0">
          {{ item.carrier_name }}
        </v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  props: {
    salesKindList: {
      type: [Object, Array],
      default: () => []
    },
    defSalesKindCode: {
      type: String,
      default: ""
    },
    defContractKindCode: {
      type: String,
      default: ""
    },
    notificationItems: {
      type: [Object, Array],
      default: () => []
    },
    //showDrawer: Boolean
  },
  data: () => ({
    links: [
      {
        to: '/',
        icon: 'mdi-home',
        text: 'Home'
      },
    ],
    title: 'Price Management System',
    responsive: false,
    responsiveInput: false,
    enableBackBtn: false,
    toggle_exclusive: undefined,
    tabsel_carrier: 0,
    select_carrier_code: 0,
    carriersList: [],
    user: null,
    fav: true,
    menu: false,
    message: false,
    hints: true,
    qry_sales_kind: "",
    qry_contract_kind: "",
    contractKindList: [],
    selectNotificationIndex: null,
    showNotificationItem: null,
  }),

  computed: {
    ...mapGetters(['authorized', 'authuser']),
    isStoreAdmin() {
      return this.user && this.user.access_level==1
    },
    showCarrierTabSelect(){
      return this.carriersList.length>1 && this.responsive && !this.enableBackBtn
    },
    showCarrierSelect(){
      return this.carriersList.length>1 && !this.responsive && !this.enableBackBtn
    },
    titleClass() {
      if (this.responsive) {
        return "text-subtitle-1 font-weight-light text-white"
      }
      return "text-h6 font-weight-light text-white"
    },
    notificationNewCount() {
      let retNum = 0
      if (this.notificationItems.length > 0) {
        retNum = this.notificationItems.reduce(function(sum, element){
          return sum + element.is_new
        }, 0)
            
      }
      return retNum
    },
    notificationTitle() {
      return function(item) {
        let reptext = item.title
        if (item.is_new == 1) {
          reptext = '<span class="red--text">●&nbsp;</span>' + reptext
        }
        return reptext
      }
    },
    notification3rdLineString() {
      return function(item) {
        let reptext = item.start_datetime
        if (item.priority > 0) {
          if (item.priority < 30) {
            reptext += "&nbsp;：&nbsp;" + item.priority_name
          } else {
            reptext += "&nbsp;：&nbsp;" + '<span class="red--text">'+item.priority_name+'</span>'
          }
        }
        return reptext
      }
    }
  },

  watch: {
    $route (val) {
      this.enableBackBtn = val.meta.headBackEnable
      //console.log(val.meta.name)
    },
    salesKindList(val) {
      this.setupSalesKind()
    },
  },

  mounted () {
    //console.log('mounted')
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
    if (this.authorized) {
      if (this.authuser) {
        try {
          //console.log(this.authuser)
          this.user = this.authuser
          this.title = this.user.store_name
          this.carriersList = this.user.carriers
        } catch (error) {
          console.log(error)
        }
        if (this.carriersList.length > 0) {
          this.tabsel_carrier = 0
          this.select_carrier_code = this.carriersList[0].carrier_code

          for (let i = 0; i < this.carriersList.length; i++) {
            if (this.carriersList[i].carrier_code == this.user.carrier_code) {
              this.tabsel_carrier = i
              break
            }
          }
        }
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },

  methods: {
    ...mapMutations(['set_auth_user']),
    onBackUrl () {
      this.$router.go(-1)
    },
    onResponsiveInverted () {
      // arrows 800px & ipad 1024px に調整
      if (window.innerWidth <= 1024) {
        this.responsive = true
        this.responsiveInput = false
      } else {
        this.responsive = false
        this.responsiveInput = true
      }
    },
    refresh () {
      //call refresh
      this.$emit('callRefresh')
    },
    resetKinds () {
      this.qry_sales_kind = this.defSalesKindCode
      this.qry_contract_kind = this.defContractKindCode
      this.changeQrySalesKind(true)
    },
    logout () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/')
      })
    },
    changeCarrier () {
      this.select_carrier_code = this.carriersList[this.tabsel_carrier].carrier_code
      this.changeCarrierSelectTab()
    },
    changeCarrierSelectTab() {
      console.log(this.select_carrier_code)
      let user = this.authuser
      if (user) {
        //console.log(user)
        if (user.carrier_code !== this.select_carrier_code) {
          user.carrier_code = this.select_carrier_code
          //store保存
          this.$store.commit('set_auth_user', {user})
          for (let i = 0; i < this.carriersList.length; i++) {
            if (this.carriersList[i].carrier_code == this.select_carrier_code) {
              this.tabsel_carrier = i
              break
            }
          }
          this.$emit('callRefresh')
        }
      }
    },
    setupSalesKind() {
      if (!this.qry_sales_kind) {
        this.qry_sales_kind = this.defSalesKindCode
      }
      if (!this.qry_contract_kind) {
        this.qry_contract_kind = this.defContractKindCode
      }
      this.changeQrySalesKind(false)
    },
    changeQrySalesKind(issue_comit=false) {
      let saleskind = this.salesKindList.find((v) => v.sales_kind_code === this.qry_sales_kind)
      let res_qrysel= []
      if (saleskind) {
        res_qrysel = saleskind.contract_kinds

        res_qrysel.sort(function(a, b){
          if (a.contract_kind_sort_no < b.contract_kind_sort_no) return -1
          if (a.contract_kind_sort_no > b.contract_kind_sort_no) return 1
          if (a.contract_kind_code < b.contract_kind_code) return -1
          if (a.contract_kind_code > b.contract_kind_code) return 1
          return 0
        })

        let constractkind = res_qrysel.find((v) => v.contract_kind_code === this.qry_contract_kind)
        if (!constractkind) {
          let constractkinddef = res_qrysel.find((v) => v.contract_kind_code === this.defContractKindCode)
          if (constractkinddef) {
            this.qry_contract_kind = this.defContractKindCode
          } else {
            this.qry_contract_kind = res_qrysel[0].contract_kind_code
          }
        }
      }
      this.contractKindList = res_qrysel
      if (issue_comit) {
        this.$emit('callQueryList', {sales_kind_code: this.qry_sales_kind, contract_kind_code: this.qry_contract_kind})
      }
    },
    changeQryContractKind() {
      this.$emit('callQueryList', {sales_kind_code: this.qry_sales_kind, contract_kind_code: this.qry_contract_kind})
    },

    showNotifivation() {
      //alert(this.selectNotificationIndex)
      this.showNotificationItem = this.notificationItems[this.selectNotificationIndex]
      this.$set(this, 'selectNotificationIndex', {})
    },

    onNotificationMenuToggle(opened) {
      if (opened) return
      this.showNotificationItem = null
      this.$set(this, 'selectNotificationIndex', {})
    }
  }
}
</script>

<style>
	#core-toolbar a {
		text-decoration: none;
  }
  
</style>

<style scoped>
  .text-notransform {
    text-transform: none !important;
  }
  .header-logo {
    max-width: calc(100% - 180px);
  }
  .pub-menu {
    min-width: 260px;
  }
</style>
