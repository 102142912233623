<template>
  <!-- eslint-disable vue/no-v-html -->
  <v-container
    container--fluid
    style="max-width:1280px;">
    <loading
      :active.sync="isDtlLoading"
      :is-full-page="false"
      color="#3aa3e3"
      :height="128"
      :width="128"
      background-color="#000"
      :opacity="0.25"
      loader="dots" />
    <core-toolbar
      :sales-kind-list="salesKindList"
      :def-sales-kind-code="authuser?authuser.def_sales_kind_code:''"
      :def-contract-kind-code="authuser?authuser.def_contract_kind_code:''"
      :notification-items="notificationItems"
      @callRefresh="refreshData"
      @callQueryList="changeKindCode" />
    
    <v-row
      align="center"
      no-gutters>
      <v-col
        :cols="responsive?4:5"
        class="pt-3">
        <v-text-field
          
          v-model="search"
          prepend-inner-icon="search"
          label="機種名検索"
          outlined
          dense
          clearable
          hide-details
          @click:clear="onKeywordClear()"
          @keydown.enter="getModels()">
          <template
            v-slot:append-outer>
            <v-btn
              dark
              color="primary"
              @click="getModels">
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        cols="auto"
        class="pt-3 pl-3">
        <select
          v-model="qryPriceRangeMode"
          name="price_range_select"
          style="border-style:solid;padding:8px 6px 8px 6px;">
          <option
            v-for="item in priceRangeModeList"
            :key="item.value"
            :value="item.value">
            {{ item.text }}
          </option>
        </select>
      </v-col>
      <v-col
        cols="auto"
        class="pt-3 pl-3">
        <material-format-number-field
          v-model="qryPriceMin"
          hide-details="auto"
          suffix="円"
          single-line
          dense
          outlined 
          style="width:8rem;" />
      </v-col>
      <v-col
        cols="auto"
        class="pt-3">
        〜
      </v-col>
      <v-col
        cols="auto"
        class="pt-3 pl-3">
        <material-format-number-field
          v-model="qryPriceMax"
          hide-details="auto"
          suffix="円"
          single-line
          dense
          outlined 
          style="width:8rem;" />
      </v-col>
      <v-col
        cols="auto"
        class="pt-3 pl-3">
        <v-btn
          
          dark
          color="primary"
          @click="getModels">
          <v-icon>
            mdi-magnify
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      align="center"
      class="mt-3">
      <v-col
        v-if="!responsive"
        cols="auto">
        <span class="body-2">
          分類：
        </span>
      </v-col>
      <v-col cols="auto mx-1">
        <select
          v-model="qryModelKindCode"
          name="modelkind_select"
          style="border-style:solid;padding:6px;"
          @change="getModels">
          <option
            v-for="item in modelKindList"
            :key="item.id"
            :value="item.kind_code">
            {{ item.kind_name }}
          </option>
        </select>
      </v-col>
      <v-col
        v-if="!responsive"
        cols="auto">
        <span class="body-2 ml-3">
          メーカー：
        </span>
      </v-col>
      <v-col cols="auto mx-1">
        <select
          v-model="qryMakerCode"
          name="msker_select"
          style="border-style:solid;padding:6px;"
          @change="getModels">
          <option
            v-for="item in makerList"
            :key="item.id"
            :value="item.maker_code">
            {{ item.maker_name }}
          </option>
        </select>
      </v-col>
      <v-col cols="auto ml-6">
        <v-btn
          small
          text
          color="primary"
          @click="clearQueryParams()">
          検索条件クリア
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col
        cols="auto"
        class="mr-2">
        {{ dispDataGetDate }}
      </v-col>
      <!--
      <v-col cols="auto mx-1" />
      <v-col cols="auto mx-1">
        <v-btn
          color="primary"
          class="hidden-xs-only"
          :disabled="checkSelected.length<=1"
          @click="viewModels">
          比較表示
        </v-btn>
      </v-col>
      -->
    </v-row>
    
    <v-row
      no-gutters
      class="mt-3">
      <v-col
        ref="resizableDiv"
        :cols="checkSelected.length==0?12:9">
        <v-data-table
          v-model="checkSelected"
          :height="listHeight"
          :mobile-breakpoint="300"
          :headers="headers"
          :items="currentViewList"
          dense
          hide-default-footer
          disable-pagination
          :fixed-header="true"
          :single-select="false"
          item-key="id"
          :header-props="{'sort-icon': ''}"
          show-select
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          class="elevation-1 table-striped main-data-list"
          @click:row="clickRow"
          @toggle-select-all="selectAllToggle"
          @update:sort-by="sortTable('by', $event)"
          @update:sort-desc="sortTable('desc', $event)">
          <template v-slot:top>
            <v-tabs
              ref="listtab"
              v-model="viewListId"
              class="mb-1"
              color="primary"
              grow
              slider-size="4">
              <v-tab
                v-for="item in viewListItems"
                :key="item.value">
                {{ item.text }}
              </v-tab>
            </v-tabs>
          </template>
          <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
            <v-simple-checkbox
              :value="isSelected"
              :readonly="item.disabled"
              :disabled="!isSelected && checkSelected.length>2"
              @input="select($event)" />
          </template>
          
          <template v-slot:[`item.model_code`]="{ item }">
            {{ item.model_name }}
          </template>
          <template v-slot:[`item.store_deposit`]="{ item }">
            <span v-html="formatNumberAmount(item.store_deposit)" />
          </template>
          <template v-slot:[`item.store_price`]="{ item }">
            <span v-html="formatNumberAmount(item.store_price)" />
          </template>
        </v-data-table>
      </v-col>
      <v-col
        v-if="checkSelected.length > 0"
        cols="3"
        class="pl-2">
        <v-card
          outlined
          color="blue-grey lighten-5">
          <v-card-title class="subtitle-2 pa-2">
            選択機種 (最大３件まで)
            <v-spacer />
            <v-btn
              small
              text
              @click="selectRemove(null)">
              CLEAR
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-card
              v-for="item in checkSelected"
              :key="item.id"
              color="#385F73"
              dark
              class="mb-1">
              <v-card-title class="subtitle-2 pa-2">
                {{ item.model_name }}
              </v-card-title>
              <v-card-actions class="pa-0 mb-1">
                <v-spacer />
                <v-btn
                  small
                  text
                  @click="selectRemove(item)">
                  <v-icon
                    small
                    class="mr-1">
                    highlight_off
                  </v-icon>
                  除外
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
          <v-divider />
          <v-card-actions class="justify-center mb-1">
            <v-btn
              color="primary"
              class="mt-1"
              style="width:8rem;"
              :disabled="checkSelected.length<=1"
              @click="viewModels">
              <v-icon
              
                class="mr-2">
                wysiwyg
              </v-icon>
              比較表示
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
      
    <v-dialog
      v-model="dtl_dialog"
      scrollable
      :fullscreen="isFullscreenDtl"
      max-width="1366px">
      <v-card>
        <v-card-title class="subtitle-1 edit-dialog-header mb-2">
          詳細情報
          <v-spacer />
          <v-btn
            icon
            dark
            @click="dtlDialogClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <dash-views-model-detail
            :item="itemDetail"
            :str-data-get-date="dtlDataGetDate"
            :is-dialog="true" />
        </v-card-text>
        <!--
        <v-divider />
        <v-card-actions>
          <v-btn
            color="blue darken-3"
            block
            text
            @click="dtlDialogClose">
            <v-icon left>
              mdi-close-thick
            </v-icon>
            閉じる
          </v-btn>
        </v-card-actions>
        -->
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import http from '@/plugins/http'
import { mapMutations, mapGetters } from 'vuex'
import { formatNumberCommaWithStyle } from "../../plugins/util.js"
import Loading from 'vue-loading-overlay'
import moment from "moment"

export default {
  components: { Loading },
  data: () => ({
    resource: "/models",
    responsive: false,
    responsiveInput: false,
    dtl_dialog: false,
    isFullscreenDtl: false,
    isDtlLoading: false,
    selected: [],
    snack: false,
    snackColor: '',
    snackText: '',
    pagination: {},
    modelList: [],
    rankingList: [],
    makerList: [],
    modelKindList: [],
    salesKindList: [],
    rowsAmount: [15, 20, 25, {'text': '$vuetify.dataIterator.rowsPerPageAll', 'value': -1}],
    search: '',
    qryMakerCode: '',
    qryModelKindCode: '',
    qrySalesKindCode: '',
    qryContractKindCode: "",
    qryPriceMin: 0,
    qryPriceMax: 0,
    qryPriceRangeMode: 0,
    priceRangeModeList: [
      { text: '頭金', value: 0 },
      { text: '総額', value: 1 },
    ],
    headers: [
      //{ text: '機種コード', value: 'model_code', sortable: false },
      { text: '機種名', value: 'model_code', sortable: true },
      { text: '頭金', value: 'store_deposit', align: 'right', sortable: true },
      { text: '総額', value: 'store_price', align: 'right', sortable: true },
      
      //, class: "d-none d-sm-block", cellClass: "d-none d-sm-block"
    ],
    sortBy: [],
    sortDesc: [],

    viewListId: 1, //検索結果をデフォルト
    viewListItems: [
      { text: '照会ランキング', value: 0 },
      { text: '検索結果', value: 1 },
      
    ],
    
    tableDataLoding: false,
    itemDetail: null,
    listHeight: "70vh",
    multiCarrierMode: false,

    checkSelected: [],
    selectedKindItem: [],
    selectedMakerItem: [],
    dispDataGetDate: "",
    dtlDataGetDate: "",

    notificationItems: [],
    reqNotificationFlag: false
  }),

  computed: {
    ...mapGetters(['authorized', 'authuser']),
    currentViewList() {
      if (this.viewListId==1) {
        return this.modelList
      } else {
        return this.rankingList
      }
    },
    formatNumberAmount() {
      return function(val, prefix="¥") {
        if (prefix) {
          if (val >= 0) {
            return prefix + " " + formatNumberCommaWithStyle(val)
          } else {
            return `<span style="color: red;">${prefix}</span>` + formatNumberCommaWithStyle(val)
          }
        }
        return formatNumberCommaWithStyle(val)
      }
    },
    windowInnerWidth () {
      return window.innerWidth
    }
  },

  watch: {
    dtl_dialog (val) {
      val || this.dtlDialogClose()
    },
    checkSelected(newVal, oldVal) {
      this.$refs.listtab.callSlider()
    },
  },
  // called when page is created before dom
  created () {
    //console.log('created.'+this.view_mode)
  },
  mounted () {
    //console.log('mounted')
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  beforeRouteEnter(to, from, next) {
    //console.log('beforeRouteEnter')
    next(vm => vm.getQueryOptions())
  },
  beforeRouteUpdate(to, from, next) {
    //console.log('beforeRouteUpdate')
    next(vm => vm.getQueryOptions())
  },
  methods: {
    refreshData() {
      //this.getQueryOptions()
      this.reqNotificationFlag = true
      this.getModels()
    },
    getQueryOptions () {
      if (this.authorized) {
        let param = {}
        let user = this.authuser
        if (user) {
          try {
            let ca_code = user.carrier_code
            param['ca_code'] = ca_code
            this.multiCarrierMode = user.carriers.length > 1
          } catch (error) {
            console.log(error)
          }
        }
        
        this.tableDataLoding = true
        http.get('/query_options', param,
          response => {
            //console.log(response)
            this.makerList = response.data.data.makers
            this.makerList.unshift({ maker_name: "(メーカー)指定なし", maker_code: "" })
            this.modelKindList = response.data.data.model_kinds
            this.modelKindList.unshift({ kind_name: "(分類)指定なし", kind_code: "" })

            let carriers = response.data.data.carriers
             
            const carrier_item = carriers.find((v) => v.carrier_code === this.authuser.carrier_code)
            let res_qrysel= []
            if (carrier_item) {
              res_qrysel = JSON.parse(JSON.stringify(carrier_item.sales_kinds))
              const sales_kind_item = res_qrysel.find((v) => v.sales_kind_code === this.qry_sales_kind)
              if (!sales_kind_item) {
                this.$set(this, 'qrySalesKindCode', this.authuser.def_sales_kind_code)
              }
            } else {
              this.$set(this, 'qrySalesKindCode', this.authuser.def_sales_kind_code)
            }
            this.$set(this, 'qryContractKindCode', this.authuser.def_contract_kind_code)
            this.salesKindList = res_qrysel
              
            this.tableDataLoding = false
            this.$nextTick(() => { 
              this.reqNotificationFlag = true
              this.getModels()
            })
          })
        
      }
    },
    getModels () {
      //一覧検索
      //this.viewListId = 1
      let param = {}
      param['sales_kind_code'] = this.qrySalesKindCode
      param['contract_kind_code'] = this.qryContractKindCode
      
      if (this.qryModelKindCode) {
        param['model_kind_code'] = this.qryModelKindCode
      }
      if (this.qryMakerCode) {
        param['maker_code'] = this.qryMakerCode
      }
      if (this.search) {
        param['key'] = this.search
      }
      param['price_range_mode'] = this.qryPriceRangeMode
      param['price_range_min'] = this.qryPriceMin
      param['price_range_max'] = this.qryPriceMax
      param['req_notification'] = this.reqNotificationFlag?1:0

      let user = this.authuser
      if (user) {
        param['ca_code'] = user.carrier_code
      }
      this.isDtlLoading = true
      http.get('/models', param,
        response => {
          //console.log(response)
          this.isDtlLoading = false
          this.modelList = response.data.data.std_list
          this.rankingList = response.data.data.ref_list
          this.dispDataGetDate = moment(response.data.data.target_datetime).format('YYYY年MM月DD日 価格')
          if (this.reqNotificationFlag) {
            this.notificationItems = response.data.data.notifications
            this.reqNotificationFlag = false
          }
          this.tableDataLoding = false
        },
        error => {
          this.isDtlLoading = false
          console.log(error.response.data)
          if (error.response.data.message) {
            alert(error.response.data.message)
          } else {
            http.handleError(error)
          }
          
        })
      
    },
    changeKindCode(params) {
      //console.log(params)
      this.checkSelected = []
      this.qrySalesKindCode = params.sales_kind_code
      this.qryContractKindCode = params.contract_kind_code
      this.getModels()
    },
    onKeywordClear() {
      this.$nextTick(() => { 
        this.search = ""
        this.getModels()
      })
    },
    // object.assign fills in the empty object with the properties of item
    clickRow (item) {
      //console.log(item)
      if (event.target.classList.contains('btn__content')) return
      //alert('Alert! \n' + item.model_name)
      this.selected = item
      
      let param = {}
      param['id'] = [item.id]
      this.isDtlLoading = true
      http.get(`/models/ids`, param,
        response => {
          //console.log(response)
          this.itemDetail = response.data.data
          this.dtlDataGetDate = moment(response.data.data.target_datetime).format('YYYY年MM月DD日 価格')
          this.isDtlLoading = false
          this.dtl_dialog = true
        })
    },
    viewModels() {
      let req_ids = []
      let param = {}
      //比較詳細データ用データ取得
      req_ids = this.checkSelected.map(item => item.id)
      param['id'] = req_ids
      this.isDtlLoading = true
      http.get(`/models/ids`, param,
        response => {
          //console.log(response)
          this.itemDetail = response.data.data
          this.dtlDataGetDate = moment(response.data.data.target_datetime).format('YYYY年MM月DD日 価格')
          this.isDtlLoading = false
          this.dtl_dialog = true
        })
      
    },
    clearQueryParams () {
      this.qryModelKindCode = ""
      this.qryMakerCode = ""
      this.search = ""
     
      this.qryPriceRangeMode = 0
      this.qryPriceMin = 0
      this.qryPriceMax = 0
      this.getModels()
    },
    onResponsiveInverted () {
      const footerHeight = 38
      //console.log(window.innerHeight + ":" + this.$refs.resizableDiv.getBoundingClientRect().height)
      // arrows 800px に調整
      if (window.innerWidth <= 800) {
        this.responsive = true
        this.responsiveInput = false

        this.listHeight = window.innerHeight - 246 - footerHeight

        /*
        if (this.multiCarrierMode) {
          this.listHeight = "47vh" //window.innerHeight - 320 - footerHeight
        } else {
          this.listHeight = "54vh" //window.innerHeight - 268 - footerHeight
        }
        */
      } else {
        this.responsive = false
        this.responsiveInput = true

        this.listHeight = window.innerHeight - 246 - footerHeight
        /*
        if (window.innerWidth < 1024) {
          this.listHeight = "59vh"
        } else {
          this.listHeight = "70vh"
        }
        */
      }
      this.$set(this, 'isFullscreenDtl', window.innerWidth <= 1400)
      
    },
    dtlDialogClose () {
      this.dtl_dialog = false
    },
    
    selectAllToggle(props) {
      if (this.checkSelected.length < 3) {
        this.checkSelected = []
        const self = this
        props.items.forEach(item => {
          if (self.checkSelected.length < 3) {
            self.checkSelected.push(item)
          } 
        })
      } else this.checkSelected = []
    },
    selectRemove(item) {
      if (item == null) {
        this.checkSelected = []
      } else {
        for (let i = 0; i < this.checkSelected.length; i++) {
          if (this.checkSelected[i].id == item.id) {
            this.checkSelected.splice(i, 1)
            break
          }
        }
      }
    },
    sortTable(byDesc, event) {
      //console.log(byDesc)
      //console.log(event)
      if (byDesc == 'by'){
        this.sortBy = event
      } else if (byDesc == 'desc'){
        this.sortDesc = event
      }

      this.headers[0].text = '機種名'
      this.headers[1].text = '頭金'
      this.headers[2].text = '総額'
      if (this.sortDesc.length > 0 && this.sortBy.length > 0) {
        switch (this.sortBy[0]) {
        case 'model_code':
          if (this.sortDesc[0]) {
            this.headers[0].text = this.headers[0].text + " ⬇︎"
          } else {
            this.headers[0].text = this.headers[0].text + " ⬆︎"
          }
          break
        case 'store_deposit':
          if (this.sortDesc[0]) {
            this.headers[1].text = this.headers[1].text + " ⬇︎"
          } else {
            this.headers[1].text = this.headers[1].text + " ⬆︎"
          }
          break
        case 'store_price':
          if (this.sortDesc[0]) {
            this.headers[2].text = this.headers[2].text + " ⬇︎"
          } else {
            this.headers[2].text = this.headers[2].text + " ⬆︎"
          }
          break
        default:

          break
        }
      }
    },
  }
}
</script>

<style>
.v-input__prepend-outer {
  margin-top: 0px !important;
}

table.v-table thead tr {
  color: red !important;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.v-input__append-outer {
  margin-top: 2px !important;
}

tbody tr:hover {
  /* background-color: darkseagreen !important; */
  cursor: pointer;
}

</style>
