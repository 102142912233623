<template>
  <v-text-field
    v-bind="$attrs"
    :value="innerValue"
    :type="type"
    autocomplete="off"
    :class="inputClass"
    v-on="listeners"
    @focusin="edit();
              $nextTick(() => {
                $event.target.select()
              })"
    @focusout="display">
    <template
      v-for="(val, name) in $slots"
      v-slot:[name]>
      <slot :name="name" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "FormatNumberField",
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      editing: false
    }
  },
  computed: {
    listeners() {
      const vm = this
      return {
        ...vm.$listenres,
        input: event => vm.innerValue = event
      }
    },
    innerValue: {
      get() {
        if (this.editing) {
          return this.value != null ? this.value.toString() : ""
        } else {
          return this.value != null ? this.value.toLocaleString() : ""
        }
      },
      set(newValue) {
        if (newValue) {
          const value = newValue.length > 0 ? Number(newValue) : null
          this.$emit("input", value)
        } else {
          this.$emit("input", null)
        }
      }
    },
    type() {
      if (this.editing) {
        return "number"
      } else {
        return "text"
      }
    },
    inputClass() {
      if (this.value != null) {
        if (Number(this.value) < 0) {
          return "righted-input text-input-red"
        } else {
          return "righted-input text-input-black"
        }
      }
      return "righted-input text-input-black"
    }
  },
  methods: {
    display() {
      this.editing = false
    },
    edit() {
      this.editing = true
      
    }
  }
}
</script>

<style>
.righted-input input {
  text-align: right
}
.text-input-red .v-text-field__slot input {
   color: #f00 !important;
}
.text-input-black .v-text-field__slot input {
   color: #000 !important;
}
</style>