import moment from "moment"
import Vue from "vue"

/**
 *
 * @param obj
 * @returns {{} & any}
 */
export function copyObject(obj) {
  const copy = Object.assign({}, obj)
  return copy
}

/**
 *
 * @param obj
 * @param form
 * @param namespace
 * @returns {*|FormData}
 */
export function objectToFormData (obj, form, namespace) {
  let fd = form || new FormData()

  for (let property in obj) {
    if (obj.prototype.hasOwnProperty.call(obj, "property")) {

      let formKey = namespace
        ? namespace + '[' + property + ']'
        : property

      if (obj[property] === Object(obj[property]) && !(obj[property] instanceof File)) {
        objectToFormData(obj[property], fd, formKey)
      } else if (obj[property] instanceof Array) {
        for (let i = 0; i < obj[property].length; i++) {
          objectToFormData(obj[property][i], fd, `${formKey}[${i}]`)
        }
      } else {
        const value = obj[property] === null ? '' : obj[property]
        fd.append(formKey, value)
      }
    }
  }
  return fd
}

export function objectSort(key, order='asc') {
  return function(a, b) {
    if (!a.prototype.hasOwnProperty.call(a, "key") || !b.prototype.hasOwnProperty.call(b, "key")) {
      // property doesn't exist on either object
      return 0
    }

    const varA = typeof a[key] === 'string' ?
      a[key].toUpperCase() : a[key]
    const varB = typeof b[key] === 'string' ?
      b[key].toUpperCase() : b[key]

    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }
    return (
      order === 'desc' ? comparison * -1 : comparison
    )
  }
}

//return an array of objects according to key, value, or key and value matching
export function getObjects(obj, key, val) {
  let objects = []
  for (let i in obj) {
    if (!obj.prototype.hasOwnProperty.call(obj, "i")) continue
    if (typeof obj[i] == 'object') {
      objects = objects.concat(getObjects(obj[i], key, val))
    } else
    //if key matches and value matches or if key matches and value is not passed (eliminating the case where key matches but passed value does not)
    if (i == key && obj[i] == val || i == key && val == '') { //
      objects.push(obj)
    } else if (obj[i] == val && key == ''){
      //only add if the object is not already in the array
      if (objects.lastIndexOf(obj) == -1){
        objects.push(obj)
      }
    }
  }
  return objects
}

//return an array of values that match on a certain key
export function getValues(obj, key) {
  let objects = []
  for (let i in obj) {
    if (!obj.prototype.hasOwnProperty.call(obj, "i")) continue
    if (typeof obj[i] == 'object') {
      objects = objects.concat(getValues(obj[i], key))
    } else if (i == key) {
      objects.push(obj[i])
    }
  }
  return objects
}

//return an array of keys that match on a certain value
export function getKeys(obj, val) {
  let objects = []
  for (let i in obj) {
    if (!obj.prototype.hasOwnProperty.call(obj, "i")) continue
    if (typeof obj[i] == 'object') {
      objects = objects.concat(getKeys(obj[i], val))
    } else if (obj[i] == val) {
      objects.push(i)
    }
  }
  return objects
}

export function formatDateTime(date = new Date(), format = 'YYYY/MM/DD HH:mm') {
  if (moment(date).isValid()) {
    return moment(date).format(format)
  } else {
    return ''
  }
}

export function formatDate(date = new Date(), format = 'YYYY/MM/DD') {
  if (moment(date).isValid()) {
    return moment(date).format(format)
  } else {
    return ''
  }
}

export function getStringToDateObject(date) {
  return moment(date).toDate()
}

export function formatNumberComma(val) {
  return Number(val).toLocaleString()
}

export function formatNumberCommaWithStyle(val) {
  if (Number(val) < 0) {
    return `<span style="color: red;">${Number(val).toLocaleString()}</span>`
  }
  return Number(val).toLocaleString()
}

export function getLastMonthStartDay(date = new Date(), format = 'YYYY/MM/DD') {
  if (moment(date).isValid()) {
    return moment(date).subtract(1, 'month').startOf('month').format(format)
  } else {
    return ''
  }
}

