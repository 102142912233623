// https://vuex.vuejs.org/en/actions.html
import axios from 'axios'
import state from './state'

// The login action passes vuex commit helper that we will use to trigger mutations.
export default {
  login ({ commit }, userData) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios.post('/login', { userid: userData.username, password: userData.password })
        .then(response => {
          const token = response.data.result.access_token
          let carrier_code = ""
          if (response.data.result.carriers.length > 0) {
            carrier_code = response.data.result.carriers[0].carrier_code
          }
          const user = {
            user_name: response.data.result.user_name,
            store_id: response.data.result.store_id,
            store_name: response.data.result.store_name,
            carrier_code: carrier_code,
            user_id: response.data.result.user_id,
            access_level: response.data.result.access_level,
            carriers: response.data.result.carriers,
            expire: response.data.result.expire,
            def_sales_kind_code: response.data.result.def_sales_kind_code,
            def_contract_kind_code: response.data.result.def_contract_kind_code
          }
          //console.log(response)
          //console.log(user)
          // storing jwt in localStorage. https cookie is safer place to store
          
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          // mutation to change state properties to the values passed along
          commit('auth_success', { token, user })
          resolve(response)
          
          //パスワード期限切れチェック
          let pwdchg_msg = ""
          if (response.data.result.manager_pwd_alert == 1) {
            pwdchg_msg = "（管理者用）"
          }
          if (response.data.result.staff_pwd_alert == 1) {
            pwdchg_msg += "（スタッフ用）"
          }
          if (pwdchg_msg !== "") {
            window.alert("前回ログインパスワード変更から一定期間が経過しました。\nログインパスワードを変更してください。\n"+pwdchg_msg)
          }
          
        })
        .catch(err => {
          console.log('login error')
          commit('auth_error')
          commit('logout')
          reject(err)
        })
    })
  },
  logout ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('logout')
      delete axios.defaults.headers.common['Authorization']
      resolve()
    })
  },
}
