/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
import store from '../store'
import NotFound from '../components/error/NotFound'
export default [
  {
    path: '*',
    meta: {
      name: '',
      requiresAuth: true
    },
    redirect: {
      path: '/'
    }
  },
  // This  allows you to have pages apart of the app but no rendered inside the dash
  {
    path: '/',
    meta: {
      name: '',
      requiresAuth: false
    },
    component: () => import(`@/components/LoginForm.vue`),
    // redirect if already signed in
    beforeEnter: (to, from, next) => {
      if (store.getters.authorized) {
        next('/price')
      } else {
        next()
      }
    },
    
  },
  // add any extra routes that you want rendered in the dashboard as a child below. Change toolbar names here
  {
    path: '/price',
    meta: {
      name: 'PriceMain',
      requiresAuth: true
    },
    component: () => import(`@/views/DashboardView.vue`),
    children: [
      {
        path: '',
        meta: {
          name: 'PriceList',
          requiresAuth: true,
          viewMode: 0,
          headBackEnable: false
        },
        component: () => import(`@/components/DashViews/ModelList.vue`)
      },
      { // 追加
        path: '/*',
        name: 'notFound',
        component: NotFound
    
      }
    ]
  },
  
]
